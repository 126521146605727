/*
  To make checkin work you need to activate ssl when fireing up the app

  run `npm run dev -- --https`

  follow the instructions on https://www.gatsbyjs.org/docs/local-https/ for setting up ssl on localhost
*/

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getBlockContent, getBlockImage } from "../utils/helpers";

// Layout
import Layout from "../components/layout/Layout";
// Containers
import RenderRichContent from "../components/containers/RenderRichContent";

const BuyTickets = ({ data }) => {
  const { pageTitle, shoutOut, metaInfo, title, slug, _rawContent } =
    data?.sanityPages || {};

  const [checkinLoaded, setCheckinLoaded] = useState(false);

  const metaStructure = {
    title: metaInfo?.header || title,
    someTitle: metaInfo?.someHeader || pageTitle,
    description:
      metaInfo?.description || getBlockContent(_rawContent) || shoutOut,
    fullSlug: slug?.current,
    image: metaInfo?.image || getBlockImage(_rawContent)?.image,
    imageAlt: metaInfo?.image
      ? metaInfo?.imageAlt
      : getBlockImage(_rawContent)?.alt,
  };

  useEffect(() => {
    let script = document.createElement("script");
    script.src = "/js/checkin2.js";

    document.head.append(script);

    script.onload = function() {
      setCheckinLoaded(true);
    };

    script.onerror = function() {
      setCheckinLoaded(false);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* <script src={"/js/checkin2.js"} /> */}
        <link rel="stylesheet" type="text/css" href={"/styles/checkin.css"} />
      </Helmet>
      <Layout metaInfo={metaStructure}>
        <div
          className="
              flex justify-between items-center
              bg-black text-white
              w-full
              py-8 px-4
              mb-2
              "
        >
          <h1 className="text-2xl w-full md:w-1/4">{pageTitle}</h1>
        </div>
        <RenderRichContent content={_rawContent} />

        {checkinLoaded ? (
          <div id="checkin_registration">&nbsp;</div>
        ) : (
          <p>
            The registration module could not be loaded. Please use another
            browser or turn off your AdBlocker
          </p>
        )}
      </Layout>
    </>
  );
};

export default BuyTickets;

export const query = graphql`
  query {
    sanityPages(slug: { current: { eq: "buy-ticket" } }) {
      pageTitle
      _rawContent(resolveReferences: { maxDepth: 5 })
      shoutOut
      slug {
        current
      }
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
